import log from "loglevel";
import { instance } from "./service";

class Mmn {

    request(data: any) {
        log.debug(`request`, data);
        return instance.post("/mmn/request", data);
    }

    history() {
        log.debug(`history`);
        return instance.get("/mmn/history");
    }

}

export default new Mmn();
