import { instance } from "./service";
import log from "loglevel";

class Tox {

    controller: any;

    /**
     * Прекращение текущего запроса к API
     */
    abort() {
        this.controller?.abort();
    }

    finish() {
        this.controller = null;
    }

    config() {
        this.controller = new AbortController();
        const signal = this.controller.signal;
        return instance.get("/tox/config", { signal });
    }

    search(data: any) {
        this.abort();
        this.controller = new AbortController();
        const signal = this.controller.signal;
        return instance.post("/tox/search", data, { signal });
    }

    buy(items: any) {
        return instance.post(`/tox/buy`, { items: items });
    }

    check(transaction_id: number) {
        return instance.get(`/tox/check/` + transaction_id);
    }

    orders() {
        log.debug("orders");
        return instance.get("/tox/orders");
    }

}

export default new Tox();
