import { RootStore } from "../root-store";

const { makeAutoObservable } = require("mobx");

class ErrorStore {

    private rootStore: RootStore;
    public _isShow: boolean = false;
    public _message: string = "";

    get isShow() {
        return this._isShow;
    }
    set isShow(value) {
        this._isShow = value;
    }

    get message() {
        return this._message;
    }
    set message(value) {
        this._message = value;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default ErrorStore;