import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";
import Api from "@api/static_page";

const { makeAutoObservable } = require("mobx");


/**
 * Стор для работы со статическими страницами
 */
class StaticPageStore {

    public rootStore: RootStore;

    public _page_data: any = {};

    get pageData() {
        return this._page_data;
    }
    set pageData(val) {
        this._page_data = val;
    }
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loadPage(name: any) {
        log.debug("getExchange");
        try {
            const res = await Api.load(name);
            console.log("PAGE", res);
            this.pageData = res.data.page;
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }
}

export default StaticPageStore;