import _ from "lodash";
import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "./root-store";
import Api from "@api/emails";
import { MSG } from "@config/messages";

class ProsStore {

    public rootStore: RootStore;

    public formData: any = {
        domain_id: 0,
        cnt: 0
    };

    private _toast: any;
    private _domains = [];
    private _min_count = 5;
    private _price = 0;
    private _show_header = true;
    private _results = [];

    get domain_id() {
        return this.formData.domain_id;
    }
    set domain_id(val) {
        this.formData.domain_id = val;
    }
    get cnt() {
        return this.formData.cnt;
    }
    set cnt(val) {
        this.formData.cnt = val;
    }
    get domains() {
        return this._domains;
    }
    set domains(val) {
        this._domains = val;
    }
    get min_count() {
        return this._min_count;
    }
    set min_count(val) {
        this._min_count = val;
    }
    get price() {
        return this._price;
    }
    set price(val) {
        this._price = val;
    }
    get showHeader() {
        return this._show_header;
    }
    set showHeader(val) {
        this._show_header = val;
    }
    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }

    setField(field: string, value: any) {
        log.debug(`setField field:${field} value:${value}`);
        // @ts-ignore
        this.formData[field] = value;
    }

    async loadDomains() {
        log.debug("loadDomains");
        try {
            const res = await Api.domains();
            this.domains = res.data.domains.map((item: any) => {
                return { "value": item.domain_id, "label": item.title };
            });
            this.min_count = res.data.min_count;
            this.price = parseFloat(res.data.price);
            this.formData.domain_id = res.data.domains[0].domain_id;
            this.formData.cnt = res.data.min_count;
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async sendForm(showLoading = true) {
        log.debug("sendForm", toJS(this.formData));
        let result = false;
        try {
            if (showLoading) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            const data = _.cloneDeep(this.formData);
            const res = await Api.request(data);
            log.debug("PROS", res);
            if (res.data.status === 'OK') {
                this.results = res.data.data;
                toast.dismiss(this._toast);
                await this.rootStore.pageStore.loadUserData();
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
        return result;
    }

    async history(show_loading = false) {
        log.debug("history");
        try {
            if (show_loading) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            const res = await Api.history();
            console.log("HISTORY", res.data.data);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                this.results = res.data.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default ProsStore;