import log from "loglevel";
import { instance } from "./service";

class Pros {

    filters() {
        log.debug("filtes");
        return instance.get("/pros/filters");
    }

    search(data: any) {
        log.debug(`search`, data);
        return instance.post("/pros/search", data);
    }

    buy(id: number) {
        log.debug(`buy id:${id}`);
        return instance.get(`/pros/buy/${id}`);
    }

}

export default new Pros();
