import log from "loglevel";
import { instance } from "./service";

class User {

    save(login: string, email: string, password: string) {
        log.debug(`save login:${login} email:${email} password:${password}`);
        return instance.post("/user", {
            login: login,
            email: email,
            password: password
        });
    }

}

export default new User();
