import log from "loglevel";
import { instance } from "./service";

class StaticPage {

    load(name: string) {
        log.debug("load name", name);
        return instance.get("/page/" + name);
    }

}

export default new StaticPage();
