import log from "loglevel";
import { instance } from "./service";

class SuperSearch {

    search(data: any) {
        log.debug(`search`, data);
        return instance.post("/super_search/search", data);
    }

    buy(sid: number, rid: number) {
        log.debug(`buy sid:${sid} rid:${rid}`);
        return instance.get(`/super_search/buy/${sid}/${rid}`);
    }

    history() {
        log.debug(`history`);
        return instance.get(`/super_search/history`);
    }

}

export default new SuperSearch();
