import log from "loglevel";
import { instance } from "./service";

class Auth {

    captcha() {
        log.debug("captcha");
        return instance.get("/captcha");
    }

    login(login: string, password: string, captcha: string) {
        log.debug("login login:" + login + " password:" + password + " captcha:" + captcha);
        return instance.post("/login", {
            login: login,
            password: password,
            captcha: captcha
        });
    }

    forgot(login: string, captcha: string) {
        log.debug("forgot login:" + login + " captcha:" + captcha);
        return instance.post("/forgot", {
            login: login,
            captcha: captcha
        });
    }

    forgotActivation(code: string) {
        log.debug("forgotActivation code:" + code);
        return instance.post("/forgot_activation", {
            code: code
        });
    }

    changePassword(password: string, captcha: string, code: string) {
        log.debug(`changePassword password:${password} captcha:${captcha} code:${code}`);
        return instance.post("/change_password", {
            password: password,
            captcha: captcha,
            code: code
        });
    }

    register(login: string, password: string, email: string, captcha: string) {
        log.debug(`register login:${login} password:${password} email:${email} captcha:${captcha}`);
        return instance.post("/register", {
            login: login,
            password: password,
            email: email,
            captcha: captcha
        });
    }

}

export default new Auth();
