import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";
import Cookie from 'mobx-cookie';
import { RootStore } from "../root-store";
import Api from "@api/auth";

/**
 * Стор авторизации для страниц login, forgot, register
 */
class AuthStore {

    private rootStore: RootStore;
    private _authenticated: boolean = false;
    public user?: string|null = null;
    public token?: string|null = null;

    public cookie = new Cookie('token');

    constructor(rootStore: any) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    get authenticated() {
        return this._authenticated;
    }
    set authenticated(val) {
        this._authenticated = val;
    }

    isAuth() {
        const auth = localStorage.getItem("token") != null;
        return this.authenticated = auth;
    }

    setToken(token: string, refresh_token: string) {
        this.token = token;
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        this.cookie.set(token);
    }

    getToken() {
        return localStorage.getItem("token");
    }

    async login(login: string, passwd: string, captcha: string) {
        log.debug("login", login, passwd, captcha);
        try {
            const res = await Api.login(login, passwd, captcha);
            if (res.data.status === 'OK') {
                this.setToken(res.data.token, res.data.refresh_token);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async register(login: string, passwd: string, email: string, captcha: string) {
        log.debug("register", login, passwd, email, captcha);
        try {
            const res = await Api.register(login, passwd, email, captcha);
            if (res.data.status === 'OK') {
                this.setToken(res.data.token, res.data.refresh_token);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async forgot(login: string, captcha: string) {
        log.debug("forgot", login, captcha);
        try {
            const res = await Api.forgot(login, captcha);
            if (res.data.status === 'OK') {
                console.log(res.data);
                this.rootStore.loginStore.forgot_send = true;
                this.rootStore.loginStore.reset();
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async forgotActivation(code: string) {
        log.debug("forgotActivation code:" + code);
        try {
            const res = await Api.forgotActivation(code);
            console.log(res.data);
            if (res.data.status === 'OK') {
                this.rootStore.loginStore.forgot_activation_code_ok = true;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async changePassword(passwd: string, captcha: string, code: string) {
        log.debug(`changePassword passwd:${passwd} captcha:${captcha}`);
        try {
            const res = await Api.changePassword(passwd, captcha, code);
            console.log(res);
            if (res.data.status === 'OK') {
                this.setToken(res.data.token, res.data.refresh_token);
                this.rootStore.loginStore.redirect = "/";
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    logout() {
        log.debug("logout");
        this.token = null;
        this.authenticated = false;
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token");
        this.rootStore?.loginStore.reset();
    }

}

export default AuthStore;