import log from "loglevel";
import { instance } from "./service";

class Finance {

    exchange() {
        log.debug(`exchange`);
        return instance.get("/exchange");
    }

    transactions(page = 1, force = false) {
        log.debug(`transactions`);
        return instance.get("/transactions/" + page + "?force=" + (force ? 1 : 0));
    }

    regenerateWallets() {
        log.debug(`regenerateWallets`);
        return instance.get("/regenerate_wallets");
    }

}

export default new Finance();
