import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";
import { RootStore } from "./root-store";
import Api from "@api/site";


class NewsStore {

    public rootStore: RootStore;

    public _content: any;

    get content() {
        return this._content;
    }
    set content(value: any) {
        this._content = value;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loadContent() {
        log.debug("loadContent");
        try {
            const res = await Api.content();
            console.log("CONTENT", res.data);
            if (res.data.status === 'OK') {
                this.content = res.data.content;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async notificationHide(notify_id: number) {
        log.debug("notificationHide notify_id:", notify_id);
        try {
            const res = await Api.notificationHide(notify_id);
            console.log("RES", res.data);
            if (res.data.status === 'OK') {
                this.content.notifications = this.content.notifications.filter((item: { notify_id: number; }) => item.notify_id !== notify_id);
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default NewsStore;