import { instance } from "./service";

class Vft {

    sections() {
        return instance.get("/vft/sections");
    }

    section(section_id: string) {
        return instance.get("/vft/section/" + section_id);
    }

    sendData(slug: string, data: any) {
        return instance.post("/vft/generate/" + slug, data);
    }

    status(task_id: string) {
        return instance.get("/vft/status/" + task_id);
    }

    purchases() {
        return instance.get("/vft/purchases");
    }

    add_basket(data: any) {
        return instance.post("/vft/basket", data);
    }

    buy(task_id: string) {
        return instance.post("/vft/buy", { task_id: task_id });
    }

    delete(task_id: string) {
        return instance.post("/vft/delete", { task_id: task_id });
    }

}

export default new Vft();
