import React from 'react';
import Rodal from 'rodal';
import { observer } from "mobx-react-lite";

import { useStore } from "@hooks/use-store";
import warning from "@assets/img/important-red.svg";
import 'rodal/lib/rodal.css';
import styles from './Error.module.scss';

const Error = () => {

    const { errorStore } = useStore();

    const hide = () => {
        errorStore.isShow = false;
    }
    return (
        <Rodal className={styles.error_dialog} visible={ errorStore.isShow } onClose={ hide.bind(this) }>
            <div className={ styles.title + " mb-2"}>Warning!</div>
            <div className="d-flex align-items-center justify-content-center">
                <img src={warning} style={{ width: "32px" }}/>
                <div className="ms-1">
                    { errorStore.message }
                </div>
            </div>
        </Rodal>
    );
};

export default observer(Error);
