import log from "loglevel";
import { toast } from "react-toastify";
import { toJS } from 'mobx';
import { RootStore } from "../root-store";
import { MSG } from "@config/messages";
import Api from "@api/new-search";
import { NewSearchFormData } from "../../types/NewSearchFormData";
const { makeAutoObservable } = require("mobx");


/**
 * Стор для работы с поиском New search
 */
class NewSearchStore {

    public rootStore: RootStore;

    private formData: NewSearchFormData = {
        firstname: "",
        lastname: "",
        dob: "",
        city: "",
        zip: "",
        state: ""
    };

    private _price: number = 0
    private _sid: number = 0;
    private _results: any = [];
    private _is_history: boolean = false;
    private _in_progress: boolean = false;
    private _in_buy_progress: boolean = false;

    private _toast: any;

    // region геттеры и сеттеры
    get firstname() {
        return this.formData.firstname;
    }
    set firstname(val) {
        this.formData.firstname = val;
    }
    get lastname() {
        return this.formData.lastname;
    }
    set lastname(val) {
        this.formData.lastname = val;
    }
    get dob() {
        return this.formData.dob;
    }
    set dob(val) {
        this.formData.dob = val;
    }
    get city() {
        return this.formData.city;
    }
    set city(val) {
        this.formData.city = val;
    }
    get zip() {
        return this.formData.zip;
    }
    set zip(val) {
        this.formData.zip = val;
    }
    get state() {
        return this.formData.state;
    }
    set state(val) {
        this.formData.state = val;
    }
    get price() {
        return this._price;
    }
    set price(val) {
        this._price = val;
    }
    get sid() {
        return this._sid;
    }
    set sid(val) {
        this._sid = val;
    }
    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }

    get is_history() {
        return this._is_history;
    }
    set is_history(val) {
        this._is_history = val;
    }

    get in_progress() {
        return this._in_progress;
    }
    set in_progress(val) {
        this._in_progress = val;
    }

    get in_buy_progress() {
        return this._in_buy_progress;
    }
    set in_buy_progress(val) {
        this._in_buy_progress = val;
    }

    setField(field: string, value: string) {
        // @ts-ignore
        this.formData[field] = value;
    }
    // endregion

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    clearForm() {
        log.debug("clearForm");
        for (let key of Object.keys(this.formData)) {
            // @ts-ignore
            this.formData[key] = "";
        }
        this.in_buy_progress = false;
    }

    async sendForm() {
        log.debug("sendForm", toJS(this.formData));
        this._toast = toast.info(MSG.MSG_LOADING);
        this.in_progress = true;
        this.in_buy_progress = false;
        let result = false;
        try {
            const res = await Api.search(this.formData);
            log.debug("NEW_SEARCH", res);
            if (res.data.status === 'OK') {
                this.price = res.data.price;
                this.sid = res.data.sid;
                this.results = res.data.data;
                toast.dismiss(this._toast);
                this.clearForm();
                result = true;
                this.is_history = false;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        } finally {
            this.in_progress = false;
        }
        return result;
    }

    async buy(id: number) {
        log.debug("buy id:", id);
        this.in_buy_progress = true;
        try {
            this._toast = toast.info(MSG.MSG_LOADING);
            const res = await Api.buy(this.sid, id);
            console.log(res);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                this.results = [ res.data.data ];
                await this.rootStore.pageStore.loadUserData();
                this.is_history = true;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        } finally {
            this.in_buy_progress = false;
        }
    }

    async history(show_loading = false) {
        log.debug("history");
        try {
            this.is_history = true;
            if (show_loading) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            const res = await Api.history();
            console.log("HISTORY", res.data.data);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                this.results = res.data.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

}

export default NewSearchStore;