import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable, when } from "mobx";

import { RootStore } from "./root-store";
import Api from "@api/site";
import { MenuItemType } from "../types/MenuItemType";


class PageStore {

    public rootStore: RootStore;

    public menuData: Array<MenuItemType>|null = null;
    public _menuOpened: boolean = false;
    public selectedMenuItem: number = 0;
    public userData: any = {
        balance_usd: 0
    };

    get menu() {
        return this.menuData;
    }
    set menu(menuData) {
        this.menuData = menuData;
    }

    get menuOpened() {
        return this._menuOpened;
    }
    set menuOpened(value) {
        this._menuOpened = value;
    }

    get user() {
        return this.userData;
    }
    set user(userData) {
        this.userData = userData;
    }

    get userBalance() {
        log.debug("userBalance");
        return this.userData ? this.userData.balance_usd : 0;
    }
    set userBalance(balance) {
        this.userData.balance_usd = balance;
    }
    get unread() {
        return this.userData.unread;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        when(
            () => rootStore.authStore.authenticated,
            async () => {
                await this.loadUserData();
            }
        );
    }

    setMenuActiveItem(endpoint: string) {
        log.debug("setMenuActiveItem endpoint:", endpoint)
        let res = this.menu?.filter(item => item.active);
        if (res?.length) {
            res[0].active = false;
        }
        res = this.menu?.filter(item => item.endpoint === endpoint);
        if (res?.length) {
            const item = res[0];
            item.active = true;
            if (item.parent_id) {
                const res1 = this.menu?.filter(item => item.section_id === item.parent_id);
                if (res1?.length) {
                    res1[0].active = true;
                }
            }
        }
    }

    async loadMenuData() {
        console.log("loadMenuData");
        try {
            const res = await Api.menu();
            console.log("MENU DATA", res.data);
            if (res.data.status === 'OK') {
                this.menu = res.data.sections;
                this.rootStore.emitter.emit("menu_data_ready");
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async loadUserData() {
        console.log("loadUserData");
        try {
            const res = await Api.user();
            console.log("USER DATA", res.data);
            if (res.data.status === 'OK') {
                this.userData = res.data.user;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default PageStore;