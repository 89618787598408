import _ from "lodash";
import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "./root-store";
import Api from "@api/fullz";
import { MSG } from "@config/messages";
import { CartItemType } from "../types/CartItemType";
import { FullzFormData } from "../types/FullzFormData";
import Utils from "@helpers/utils";

class FullzStore {

    public rootStore: RootStore;

    private formData: FullzFormData = {
        firstname: "",
        lastname: "",
        country: "",
        state: "",

        phone: false,
        email: false,
        ssn: false,
        dob: false,
        mmn: false,
        dl: false,
        account_number: false,
        routing_number: false,

        dl_state: "",
        database: "",
    };
    private _sources: any = [];
    private _toast: any;
    private _page: number = 0;
    private _pages = 0;
    private _results = [];
    private _cart: CartItemType[] = [];
    private _orders: any = [];

    // region геттеры и сеттеры
    get states() {
        let res = [];
        switch (this.formData.country) {
            case 'US':
                res = this.rootStore.configStore.states;
                break;
            case 'CA':
                res = this.rootStore.configStore.ca_states;
                break;
        }
        return res;
    }

    get sources() {
        let res = [{
            value: "",
            label: "N/A"
        }];
        for (let source of this._sources) {
            res.push({
                value: source.source_id,
                label: source.name
            });
        }
        return res;
    }

    get firstname() {
        return this.formData.firstname;
    }
    set firstname(val) {
        this.formData.firstname = val;
    }
    get lastname() {
        return this.formData.lastname;
    }
    set lastname(val) {
        this.formData.lastname = val;
    }
    get country() {
        return this.formData.country;
    }
    set country(val) {
        this.formData.country = val;
    }
    get state() {
        return this.formData.state;
    }
    set state(val) {
        this.formData.state = val;
    }
    get phone() {
        return this.formData.phone;
    }
    set phone(val) {
        this.formData.phone = val;
    }
    get email() {
        return this.formData.email;
    }
    set email(val) {
        this.formData.email = val;
    }
    get ssn() {
        return this.formData.ssn;
    }
    set ssn(val) {
        this.formData.ssn = val;
    }
    get mmn() {
        return this.formData.mmn;
    }
    set mmn(val) {
        this.formData.mmn = val;
    }
    get dob() {
        return this.formData.dob;
    }
    set dob(val) {
        this.formData.dob = val;
    }
    get dl() {
        return this.formData.dl;
    }
    set dl(val) {
        this.formData.dl = val;
    }
    get account_number() {
        return this.formData.account_number;
    }
    set account_number(val) {
        this.formData.account_number = val;
    }
    get routing_number() {
        return this.formData.routing_number;
    }
    set routing_number(val) {
        this.formData.routing_number = val;
    }
    get dl_state() {
        return this.formData.dl_state;
    }
    set dl_state(val) {
        this.formData.dl_state = val;
    }
    get database() {
        return this.formData.database;
    }
    set database(val) {
        this.formData.database = val;
    }
    get page() {
        return this._page;
    }
    set page(val) {
        this._page = val;
    }
    get pages() {
        return this._pages;
    }
    set pages(val) {
        this._pages = val;
    }
    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }
    get cart() {
        return this._cart;
    }
    set cart(val) {
        this._cart = val;
    }
    get orders() {
        return this._orders;
    }
    set orders(val) {
        this._orders = val;
    }

    setField(field: string, value: any) {
        log.debug(`setField field:${field} value:${value}`);
        // @ts-ignore
        this.formData[field] = value;
    }
    // endregion

    clearForm() {
        log.debug("clearForm");
        for (let key of Object.keys(this.formData)) {
            // @ts-ignore
            this.formData[key] = typeof(this.formData[key]) === "boolean" ? false : "";
        }
        this.clearResults();
    }

    clearResults() {
        log.debug("clearResults");
        this.pages = 0;
        this.page = 0;
        this.results = [];
        this.cart = [];
    }

    async sendForm(page: number) {
        log.debug("sendForm", toJS(this.formData));
        let result = false;
        try {
            this.page = page;
            this._toast = toast.info(MSG.MSG_LOADING);
            const data = _.cloneDeep(this.formData);
            // @ts-ignore
            data.page = this.page;
            const res = await Api.search(data);
            log.debug("FULLZ_SEARCH", res);
            if (res.data.status === 'OK') {
                this.results = res.data.data;
                this.pages = res.data.pages;
                toast.dismiss(this._toast);
                result = true;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
        return result;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async loadSources() {
        log.debug("loadSources");
        try {
            const res = await Api.sources();
            console.log("SOURCES", res.data);
            if (res.data.status === 'OK') {
                this._sources = res.data.sources;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    selectAll(state: boolean) {
        for (let row of this.results) {
            // @ts-ignore
            row.selected = state;
        }
    }

    selectedCount() {
        // @ts-ignore
        return (this.results.filter(item => item.selected)).length;
    }

    cartTotal() {
        const res = {
            count: 0,
            sum: 0
        }
        this._cart.map(item => {
            res.count++;
            res.sum += item.price;
        });
        return res;
    }

    inCart(data_id: number) {
        return this.cart.filter(item => item.data_id === data_id).length;
    }

    addToCart(data_id: number, price: number) {
        log.debug("addToCart data_id:", data_id);
        if (!this.cart.filter(row => row.data_id === data_id).length) {
            this.cart.push({
                data_id: data_id,
                price: price
            });
        }
    }

    deleteFromCart(data_id: number) {
        log.debug("deleteFromCart data_id:", data_id);
        this.cart = this.cart.filter(item => item.data_id !== data_id);
    }

    async buy() {
        log.debug("buy");
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            const data = this.cart.map((row: CartItemType) => {
                return row.data_id;
            });
            const res = await Api.buy(data);
            console.log("BUY RESULT", res);
            if (res.data.status === 'OK') {
                for (let item of res.data.data.processed) {
                    this.deleteFromCart(item);
                }
                toast.dismiss(this._toast);
                this._toast = toast.info(Utils.stringFormat(MSG.MSG_SUCCESS_PURCHASED, [res.data.data.processed.length]));
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    async loadOrders() {
        log.debug("loadOrders");
        try {
            const res = await Api.orders();
            console.log("ORDERS", res.data);
            if (res.data.status === 'OK') {
                this._orders = res.data.orders;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default FullzStore;