export const MSG = {
    MSG_PASSWORD_AND_CONFIRM_DONT_MATH: "Password and repeat password do not match",
    MSG_GOTO_BTC: "Open transaction info",
    MSG_COPY_TO_CLIPBOARD: "Copy to clipboard",
    MSG_LOADING: "Loading...",
    MSG_WALLET_COPIED: "Wallet number copied to clipboard",
    MSG_INVALID_DATE: "Invalid date of birth",
    MSG_SEARCH_RESULTS_READY: "Your search results is ready",
    MSG_ADD_TO_QUEUE: "Added to queue {0} items",
    MSG_SUCCESS_PURCHASED: "Successfully purchased {0} items",

    MSG_WAIT: "The waiting time for the result can be from 3 seconds to 5 minutes.",
}