import _ from "lodash";
import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "./root-store";
import Api from "@api/pros";
import { MSG } from "@config/messages";

class ProsStore {

    public rootStore: RootStore;

    public formData: any = {
        status: "",
        age: "",
        state: "",
        score: "",
        dl: false,
        mmn: false
    };

    private _toast: any;
    private _filters = {
        status: [],
        age: [],
        score: []
    };
    private _page = 0;
    private _pages = 0;
    private _results = [];

    get filters() {
        return this._filters;
    }
    set filters(val) {
        this._filters = val;
    }
    get page() {
        return this._page;
    }
    set page(val) {
        this._page = val;
    }
    get pages() {
        return this._pages;
    }
    set pages(val) {
        this._pages = val;
    }
    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }

    setField(field: string, value: any) {
        log.debug(`setField field:${field} value:${value}`);
        // @ts-ignore
        this.formData[field] = value;
    }

    async sendForm(page: number, showLoading = true) {
        log.debug("sendForm", toJS(this.formData));
        let result = false;
        try {
            this.page = page;
            if (showLoading) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            const data = _.cloneDeep(this.formData);
            // @ts-ignore
            data['page'] = this.page;
            const res = await Api.search(data);
            log.debug("PROS", res);
            if (res.data.status === 'OK') {
                this.results = res.data.data;
                this.pages = res.data.pages;
                toast.dismiss(this._toast);
                result = this.pages > 0;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
        return result;
    }

    async loadFilters() {
        log.debug("loadFilters");
        try {
            const res = await Api.filters();
            this.filters.status = res.data.status.map((item: any) => {
                return { "value": item.name, "label": item.title };
            });
            this.formData.status = res.data.status[0]['name'];
            this.filters.age = res.data.age.map((item: any, i: number) => {
                return { "value": i, "label": item.title };
            });
            this.filters.score = res.data.score.map((item: any, i: number) => {
                return { "value": item.name, "label": item.title };
            });
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async buy(id: number) {
        log.debug("buy id:", id);
        try {
            this._toast = toast.info(MSG.MSG_LOADING);
            const res = await Api.buy(id);
            console.log(res);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                console.log(res.data);
                await this.sendForm(this.page);
                await this.rootStore.pageStore.loadUserData();
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.loadFilters().then();
    }

}

export default ProsStore;