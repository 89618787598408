import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "../root-store";
import Api from "@api/site";
import Config from "@config/config";

const { makeAutoObservable } = require("mobx");

/**
 * Стор для загрузки конфига с сервера
 */
class ConfigStore {

    public rootStore: RootStore;

    private _config: any;
    private _countries: any = [];
    private _states: any = [];
    private _ca_states: any = [];

    // region getter & setters
    get config() {
        return this._config;
    }
    set config(val) {
        this._config = val;
    }
    get countries() {
        return this._countries;
    }
    set countries(val) {
        this._countries = val;
    }
    get states() {
        return this._states;
    }
    set states(val) {
        this._states = val;
    }
    get ca_states() {
        return this._ca_states;
    }
    set ca_states(val) {
        this._ca_states = val;
    }
    // endregion


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.load().then();
    }

    async load() {
        log.debug("ConfigStore.load");
        try {
            const res = await Api.config();
            this.config = res.data.settings;
            console.log("config", this.config);
            Config.modal_links[0].link = this.config.links.onion.link;
            Config.modal_links[0].title = this.config.links.onion.title;
            Config.modal_links[1].link = this.config.links.tg.link;
            Config.modal_links[1].title = this.config.links.tg.title;
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async loadCountries() {
        log.debug("loadCountries");
        try {
            const res = await Api.countries();
            this._countries = [];
            for (let country of res.data) {
                this._countries.push({ "value": country.code, "label": country.name });
            }
            return res.data;
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async loadUsaStates() {
        log.debug("loadUsaStates");
        try {
            const res = await Api.usa_states();
            this._states = res.data;
            return res.data;
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async loadCanadaStates() {
        log.debug("loadCanadaStates");
        try {
            const res = await Api.canada_states();
            this._ca_states = res.data;
            return res.data;
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default ConfigStore;