import log from "loglevel";
import { instance } from "./service";

class Site {

    config() {
        log.debug("config");
        return instance.get("/config");
    }

    ping() {
        log.debug("ping");
        return instance.get("/ping");
    }

    /**
     * Загрузка данных главного меню в топе
     */
    menu() {
        log.debug("menu");
        return instance.get("/menu");
    }

    /**
     * Загрузка уведомлений и новостей
     */
    content() {
        log.debug("content");
        return instance.get("/content");
    }

    notificationHide(notify_id: number) {
        log.debug("notificationHide notify_id:", notify_id);
        return instance.post("/notification/hide", {
            notify_id: notify_id
        });
    }

    user() {
        log.debug("user");
        return instance.get("/user");
    }

    countries() {
        log.debug("countries");
        return instance.get("/countries");
    }

    usa_states() {
        log.debug("usa_states");
        return instance.get("/usa_states");
    }

    canada_states() {
        log.debug("canada_states");
        return instance.get("/canada_states");
    }

}

export default new Site();
