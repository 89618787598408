import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "../root-store";
import Api from "@api/auth";
import ApiUser from "@api/user";

const { makeAutoObservable } = require("mobx");

/**
 * Стор для страниц login, forgot, register
 */
class LoginStore {

    private rootStore: RootStore;

    public _captcha: string = "Loading..";
    public redirect: string|null = null;
    public forgot_send: boolean = false;
    public forgot_activation_code_ok: boolean = false;

    public data = {
        login: "",
        password: "",
        repeat_password: "",
        email: "",
        captcha: "",
        code: ""
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.getCaptcha().then();
    }

    /**
     * Возвращает svg-картинку капчи
     */
    get captcha() {
        return this._captcha;
    }
    set captcha(captcha: string) {
        this.data.captcha = captcha;
    }

    set login(login: string) {
        this.data.login = login;
    }
    set password(password: string) {
        this.data.password = password;
    }
    set repeat_password(password: string) {
        this.data.repeat_password = password;
    }
    set email(email: string) {
        this.data.email = email;
    }
    set code(code: string) {
        this.data.code = code;
    }

    setField(field: string, value: string) {
        if (Object.keys(this.data).includes(field)) {
            // @ts-ignore
            this.data[field] = value;
        }
    }

    reset(update_captcha = true) {
        log.debug("reset update_captcha:" + update_captcha);
        this.data.login = "";
        this.data.password = "";
        this.data.repeat_password = "";
        this.data.email = "";
        this.data.captcha = "";
        if (update_captcha) {
            this.getCaptcha().then();
        }
    }

    /**
     * Обновление картинки капчи
     */
    async getCaptcha() {
        log.debug("getCaptcha");
        let result;
        try {
            const res = await Api.captcha();
            result = res.data;
        } catch (ex: any) {
            result = ex.message;
        }
        this._captcha = result;
    }

    async sendLoginForm() {
        log.debug("sendLoginForm");
        await this.rootStore.authStore.login(this.data.login, this.data.password, this.data.captcha);
    }

    async sendForgotForm() {
        log.debug("sendForgotForm");
        await this.rootStore.authStore.forgot(this.data.email, this.data.captcha);
    }

    async sendForgotActivationForm() {
        log.debug("sendForgotActivationForm");
        await this.rootStore.authStore.forgotActivation(this.data.code);
    }

    async sendChangePasswordForm() {
        log.debug("sendChangePasswordForm");
        await this.rootStore.authStore.changePassword(this.data.password, this.data.captcha, this.data.code);
    }

    async sendRegisterForm() {
        log.debug("sendRegisterForm");
        await this.rootStore.authStore.register(this.data.login, this.data.password, this.data.email, this.data.captcha);
    }

    async saveUserData() {
        log.debug("saveUserData", this.data.login);
        try {
            const res = await ApiUser.save(this.data.login, this.data.email, this.data.password);
            log.debug(res);
            if (res.data.status === 'OK') {
                await this.rootStore.pageStore.loadUserData();
                this.reset(false);
                toast.success("Your profile has been updated successfully");
            }
        } catch (ex: any) {
            toast.error(ex.response.data.message || ex.message);
        }
    }

}

export default LoginStore;