import log from 'loglevel';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import './css/index.css';
import './css/mobile.css';

import { UseStore } from "@hooks/use-store"
import { RootStore } from "@store/root-store"


//log.setLevel("debug");
log.enableAll();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <UseStore.Provider value={ new RootStore() }>
      <App />
    </UseStore.Provider>
  </React.StrictMode>
);
