import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";


import Loading from "@components/loading/Loading";

const ProtectedRoute   = React.lazy(() => import('./protected-route'));
const Login            = React.lazy(() => import('../views/auth/login/Login'));
const Registration     = React.lazy(() => import('../views/auth/registration/Registration'));
const Logout           = React.lazy(() => import('../views/auth/logout/Logout'));
const Forgot           = React.lazy(() => import('../views/auth/forgot/Forgot'));
const ForgotActivation = React.lazy(() => import('../views/auth/forgot_activation/ForgotActivation'));
const ChangePassword   = React.lazy(() => import('../views/auth/change_password/ChangePassword'));
const Main             = React.lazy(() => import('../views/main/Main'));

const AppRoutes = () => {


    return (
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route path="login" element={<Login/>} />
                <Route path="logout" element={<Logout/>} />
                <Route path="registration" element={<Registration/>} />

                <Route path="forgot" element={<Forgot/>} />
                <Route path="forgot_activation" element={<ForgotActivation/>} />
                <Route path="change_password" element={<ChangePassword/>} />

                <Route path='*' element={<ProtectedRoute><Main/></ProtectedRoute>} />
            </Routes>
        </Suspense>
    );
}

export default observer(AppRoutes);