import log from "loglevel";
import { instance } from "./service";

class PremiumSearch {

    search(data: any) {
        log.debug(`search`, data);
        return instance.post("/premium_search/search", data);
    }

    history() {
        log.debug(`history`);
        return instance.get(`/premium_search/history`);
    }

    again(request_id: number) {
        log.debug(`again request_id:`, request_id);
        return instance.get(`/premium_search/again/` + request_id);
    }

}

export default new PremiumSearch();
