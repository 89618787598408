import _ from "lodash";
import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable, toJS } from "mobx";
import { MSG } from "@config/messages";
import { RootStore } from "./root-store";
import Api from "@api/used";

class UsedStore {

    public rootStore: RootStore;

    public formData: any = {
        firstname: "",
        lastname: "",
        state: "",
        zip: "",
        city: ""
    };

    private _toast: any;
    private _price: number = 0
    private _page = 0;
    private _pages = 0;
    private _results: any = [];
    private _isHistory = true;
    private _orders: any = [];

    // region геттеры и сеттеры
    get price() {
        return this._price;
    }
    set price(val) {
        this._price = val;
    }
    get page() {
        return this._page;
    }
    set page(val) {
        this._page = val;
    }
    get pages() {
        return this._pages;
    }
    set pages(val) {
        this._pages = val;
    }
    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }
    get isHistory() {
        return this._isHistory;
    }
    set isHistory(val) {
        this._isHistory = val;
    }
    get orders() {
        return this._orders;
    }
    set orders(val) {
        this._orders = val;
    }

    setField(field: string, value: any) {
        log.debug(`setField field:${field} value:${value}`);
        // @ts-ignore
        this.formData[field] = value;
    }
    // endregion

    clearForm() {
        for (let key of Object.keys(this.formData)) {
            // @ts-ignore
            this.formData[key] = "";
        }
    }

    async getRandom() {
        log.debug("getRandom");
        const res = await Api.random();
        this.results = [];
        this.pages = 0;
        if (res.data.status === 'OK') {
            this.results = res.data.data;
            this.pages = 1;
            this.isHistory = false;
            this.price = res.data.price.usd;
        }
    }

    async sendForm(page: number, showLoading = true) {
        log.debug("sendForm", toJS(this.formData));
        let result = false;
        try {
            this.page = page;
            if (showLoading) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            const data = _.cloneDeep(this.formData);
            // @ts-ignore
            data['page'] = this.page;
            const res = await Api.search(data);
            log.debug("USED", res);
            if (res.data.status === 'OK') {
                this.isHistory = false;
                this.results = res.data.data;
                this.pages = res.data.pages;
                toast.dismiss(this._toast);
                result = this.pages > 0;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
        return result;
    }

    async buy(id: number, type: string) {
        log.debug("buy id:", id);
        try {
            this._toast = toast.info(MSG.MSG_LOADING);
            const res = await Api.buy(id, type);
            console.log(res);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                console.log(res.data);
                await this.loadOrders();
                await this.rootStore.pageStore.loadUserData();
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    async loadOrders(page: number = 1) {
        log.debug("loadOrders");
        this.page = page;
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            const res = await Api.orders(page);
            log.debug("ORDERS", res.data);
            if (res.data.status === 'OK') {
                this.results = res.data.orders;
                this.pages = res.data.pages;
                this.isHistory = true;
                toast.dismiss(this._toast);
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default UsedStore;