import axios from "axios";
import Cookie from 'mobx-cookie';
import Config from "@config/config";
import AuthStore from '@store/auth/auth-store';

const authStore = new AuthStore(null);
const cookie = new Cookie('token');

export const instance = axios.create({
    withCredentials: true,
    baseURL: Config.API,
    onUploadProgress: (progressEvent) => {
        // @ts-ignore
        instance.rootStore.emitter.emit('upload', progressEvent.loaded);
    }
});

instance.interceptors.request.use(
    (config) => {
        config.headers['x-auth-token'] = `${localStorage.getItem("token")}`;
        return config;
    }
);

instance.interceptors.response.use(
    // в случае валидного accessToken ничего не делаем:
    (config) => {
        return config;
    },
    // в случае просроченного accessToken пытаемся его обновить:
    async (error) => {
        console.log("Service error", error);
        // предотвращаем зацикленный запрос, добавляя свойство _isRetry
        const originalRequest = {...error.config};
        originalRequest._isRetry = true;
        if (
            error.response &&
            // проверим, что ошибка именно из-за невалидного accessToken
            error.response.status === 401 &&
            // проверим, что запрос не повторный
            error.config &&
            !error.config._isRetry
        ) {
            try {
                console.log("send refresh");
                // запрос на обновление токенов
                const resp = await instance.post("/refresh", {
                    refresh_token: localStorage.getItem("refresh_token")
                });
                // сохраняем новый accessToken в localStorage
                localStorage.setItem("token", resp.data.token);
                cookie.set(resp.data.token);
                // переотправляем запрос с обновленным accessToken
                return instance.request(originalRequest);
            } catch (error: any) {
                console.log("AUTH ERROR", error['message']);
                authStore.logout();
                document.location.href = "/login";
            }
        }
        // на случай, если возникла другая ошибка (не связанная с авторизацией)
        // пробросим эту ошибку
        throw error;
    }
);