import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";
import { RootStore } from "./root-store";
import Api from "@api/tickets";
import { MSG } from "@config/messages";

class TicketsStore {

    public rootStore: RootStore;

    private _mode = "main";
    private _toast: any;
    private _groups = [];
    private _page = 1;
    private _pages = 0;
    private _tickets: any = [];
    private _ticket_statuses: any = [];

    private _selected_ticket_id = 0;
    private _selected_group = 0;
    private _show_messages = false;
    private _show_history = false;
    private _archive = false;

    private _title = "";
    private _message = "";

    private _messages = [];

    private _support_users: any = [];
    private _recipient: any;

    // region геттеры и сеттеры
    get mode() {
        return this._mode;
    }
    set mode(val) {
        this._mode = val;
    }
    get groups() {
        return this._groups;
    }
    set groups(val) {
        this._groups = val;
    }
    get page() {
        return this._page;
    }
    set page(val) {
        this._page = val;
    }
    get pages() {
        return this._pages;
    }
    set pages(val) {
        this._pages = val;
    }
    get selected_ticket_id() {
        return this._selected_ticket_id;
    }
    set selected_ticket_id(val) {
        this._selected_ticket_id = val;
    }
    get selected_group() {
        return this._selected_group;
    }
    set selected_group(val) {
        this._selected_group = val;
    }
    get tickets() {
        return this._tickets;
    }
    set tickets(val) {
        this._tickets = val;
    }
    get ticket_statuses() {
        return this._ticket_statuses;
    }
    set ticket_statuses(val) {
        this._ticket_statuses = val;
    }
    get isShowMessages() {
        return this._show_messages;
    }
    set isShowMessages(val) {
        this._show_messages = val;
    }
    get isShowHistory() {
        return this._show_history;
    }
    set isShowHistory(val) {
        this._show_history = val;
    }
    get isArchive() {
        return this._archive;
    }
    set isArchive(val) {
        this._archive = val;
    }
    get title() {
        return this._title;
    }
    set title(val) {
        this._title = val;
    }
    get message() {
        return this._message;
    }
    set message(val) {
        this._message = val;
    }
    get messages() {
        return this._messages;
    }
    set messages(val) {
        this._messages = val;
    }

    get support_users() {
        return this._support_users;
    }
    set support_users(val) {
        this._support_users = val;
    }

    get recipient() {
        return this._recipient;
    }
    set recipient(val) {
        this._recipient = val;
    }

    get selectedCount() {
        log.debug("selectedCount");
        return this.tickets.filter((item: any) => item.selected).length;
    }

    get selectedTicketMessage() {
        log.debug("selectedTicketMessage", this.selected_ticket_id);
        return this.tickets.filter((item: any) => item.ticket_id === this.selected_ticket_id)[0];
    }

    get attacheStore() {
        return this.rootStore.attacheStore;
    }
    // endregion

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    getStatusName(ticket_status_id: number, field: string) {
        log.debug("getStatusName", ticket_status_id);
        const res = this.ticket_statuses.filter((item: any) => item.ticket_status_id === ticket_status_id);
        return res.length ? res[0][field] : "";
    }

    getSelectedTickets() {
        log.debug("getSelectedTickets");
        let list: number[] = [];
        // eslint-disable-next-line array-callback-return
        this.tickets.map((item: any) => {
            if (item.selected) {
                list.push(item.ticket_id);
            }
        });
        return list;
    }

    async loadGroups() {
        log.debug("loadGroups");
        try {
            const res = await Api.groups();
            this.groups = res.data.groups;
            this.ticket_statuses = res.data.statuses;

            this.support_users = [];
            for (let user of this.groups) {
                const item = {
                    "value": user['ticket_group_id'], "label": user['title']
                }
                if (!this.recipient) {
                    this.recipient = item
                }
                this.support_users.push(item);
            }

        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async loadTickets(ticket_group_id: number = 0, showLoading = true) {
        log.debug("loadTickets ticket_group_id", ticket_group_id);
        if (showLoading) {
            this._toast = toast.info(MSG.MSG_LOADING);
        }
        try {
            this.tickets = [];
            this.selected_group = ticket_group_id;
            const res = await Api.tickets({
                ticket_group_id: ticket_group_id,
                archive: this.isArchive,
                page: this.page
            });
            if (res.data.status === 'OK') {
                this.tickets = res.data.tickets;
                toast.dismiss(this._toast);
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    /**
     * Отмечает, что тикет выбран/не выбран
     * @param ticket_id
     * @param state
     */
    selectTicket(ticket_id: number, state: boolean) {
        log.debug(`selectTicket ticket_id:${ticket_id} state:${state}`);
        this.tickets.map((item: any) => {
            if (item.ticket_id === ticket_id) {
                item.selected = state;
            }
        });
    }

    /**
     * Перемещает выбранные тикеты в архив
     * @param list      Список ID тикетов
     */
    async moveToArchive(list: any[]) {
        log.debug(`moveToArchive`);
        if (list.length) {
            this._toast = toast.info(MSG.MSG_LOADING);
            try {
                const res = await Api.to_archive(list);
                console.log(res);
                if (res.data.status === 'OK') {
                    await this.loadTickets(this.selected_group, false);
                    toast.dismiss(this._toast);
                } else {
                    toast.dismiss(this._toast);
                    toast.error(res.data.message);
                }
            } catch (ex: any) {
                log.debug(ex);
                toast.dismiss(this._toast);
                toast.error(ex.response?.data.message || ex.message);
            }
        }
    }

    /**
     * Удаление сообщений
     * @param list      Список ID тикетов
     */
    async delete(list: any[]) {
        log.debug(`delete`);
        if (list.length) {
            this._toast = toast.info(MSG.MSG_LOADING);
            try {
                const res = await Api.delete(list);
                console.log(res);
                if (res.data.status === 'OK') {
                    await this.loadTickets(this.selected_group, false);
                    toast.dismiss(this._toast);
                } else {
                    toast.dismiss(this._toast);
                    toast.error(res.data.message);
                }
            } catch (ex: any) {
                log.debug(ex);
                toast.dismiss(this._toast);
                toast.error(ex.response?.data.message || ex.message);
            }
        }
    }

    async getMessages(showLoading: boolean = true) {
        log.debug(`getMessages ticket_id:${this.selected_ticket_id}`);
        if (showLoading) {
            this._toast = toast.info(MSG.MSG_LOADING);
        }
        try {
            const res = await Api.messages(this.selected_ticket_id);
            console.log(res);
            if (res.data.status === 'OK') {
                this.messages = res.data.messages;
                toast.dismiss(this._toast);
                await this.rootStore.pageStore.loadUserData();
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async sendMessage() {
        log.debug(`sendMessage ticket_id:${this.selected_ticket_id}`, this.message);
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            let res: any = await this.uploadFiles();
            console.log(res);
            if (res && res.data.status === 'OK') {
                res = await Api.send_message(this.selected_ticket_id, this.message, res.data.hash);
                console.log(res);
                if (res.data.status === 'OK') {
                    this.message = "";
                    this.clearAttaches();
                    await this.getMessages(false);
                    toast.dismiss(this._toast);
                } else {
                    toast.dismiss(this._toast);
                    toast.error(res.data.message);
                }
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    /**
     * Отправка аттачей сообщения
     */
    async uploadFiles() {
        log.debug(`uploadFiles`);
        try {
            return await Api.upload(this.attacheStore.attaches);
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    clearNewTicketForm() {
        this.title = "";
        this.message = "";
        this.clearAttaches();
    }

    clearAttaches() {
        this.attacheStore.clearAttaches();
    }

    /**
     * НЕ ИСПОЛЬЗУЕТСЯ!
     * Получение юзеров саппорта для поля получатель
     * @param ticket_group_id {number}
     */
    async getSupportUsers(ticket_group_id: number) {
        log.debug("getSupportUsers ticket_group_id", ticket_group_id);
        try {
            const res = await Api.support_users(ticket_group_id);
            this.support_users = [];
            if (res.data.status === "OK") {
                log.debug(res);
                for (let user of res.data.supports) {
                    console.log(user);
                    this.support_users.push({
                        "value": user.user_id, "label": user.name
                    });
                }
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async sendTicket() {
        log.debug(`sendTicket ticket_group_id:${this.selected_group} title:${this.title} message:${this.message}`);
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            let res: any = await this.uploadFiles();
            console.log(res);
            if (res && res.data.status === 'OK') {
                res = await Api.send_ticket(this.selected_group, this.title, this.message, res.data.hash);
                console.log(res);
                if (res.data.status === 'OK') {
                    this.title = "";
                    this.message = "";
                    this.clearAttaches();
                    toast.dismiss(this._toast);
                    if (res && res.data.status === 'OK') {
                        this.mode = "main";
                        this.isShowMessages = true;
                        this.page = 1;
                        await this.loadTickets(this.selected_group);
                    } else {
                        toast.error(res.data.message);
                    }
                } else {
                    toast.dismiss(this._toast);
                    toast.error(res.data.message);
                }
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default TicketsStore;