import log from "loglevel";
import { instance } from "./service";

class NewSearch {

    search(data: any) {
        log.debug(`search`, data);
        return instance.post("/new_search/search", data);
    }

    buy(sid: number, id: number) {
        log.debug(`buy sid:${sid} id:${id}`);
        return instance.get(`/new_search/buy/${sid}/${id}`);
    }

    history() {
        log.debug(`history`);
        return instance.get("/new_search/history");
    }

}

export default new NewSearch();
