import log from "loglevel";
import { instance } from "./service";

class Used {

    random(): any {
        log.debug(`random`);
        return instance.get("/used/random");
    }

    search(data: any) {
        log.debug(`search`, data);
        return instance.post("/used/search", data);
    }

    buy(id: number, type: string) {
        log.debug(`buy id:${id}`);
        return instance.post(`/used/buy`, {
            type: type,
            id: id
        });
    }

    orders(page: number) {
        log.debug("orders");
        return instance.get("/used/orders/" + page);
    }

}

export default new Used();
