import { RootStore } from "../root-store";

const { makeAutoObservable } = require("mobx");

class ModalStore {

    private rootStore: RootStore;

    public isShow: boolean = false;
    public className: string = "";
    public title: string = "Warning!";
    public message: any;
    public closeMaskOnClick: boolean = true;
    public showCloseButton: boolean = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

}

export default ModalStore;