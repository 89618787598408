import log from "loglevel";
import { instance } from "./service";

class Tickets {

    groups() {
        log.debug("groups");
        return instance.get("/tickets/groups");
    }

    tickets(options: any) {
        log.debug("groups");
        return instance.post("/tickets", options);
    }

    to_archive(tickets_ids: number[]) {
        log.debug("groups");
        return instance.post("/tickets/to_archive", {
            tickets_ids: tickets_ids
        });
    }

    delete(tickets_ids: number[]) {
        log.debug("groups");
        return instance.post("/tickets/delete", {
            tickets_ids: tickets_ids
        });
    }

    send_message(ticket_id: number, message: string, hash: string) {
        log.debug("send_message");
        return instance.post("/tickets/message", {
            ticket_id: ticket_id,
            message: message,
            hash: hash
        });
    }

    messages(ticket_id: number) {
        log.debug("messages");
        return instance.get("/tickets/messages/" + ticket_id);
    }

    upload(attaches: any[]) {
        log.debug("upload", attaches);
        const formData = new FormData();
        attaches.forEach(file => {
            formData.append("files", file);
        });
        return instance.post("/tickets/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }

    support_users(ticket_group_id: number) {
        log.debug("messages");
        return instance.get("/tickets/recipients/" + ticket_group_id);
    }

    send_ticket(ticket_group_id: number, title: string, message: string, hash: string) {
        log.debug("send_ticket");
        return instance.post("/tickets/ticket", {
            ticket_group_id: ticket_group_id,
            title: title,
            message: message,
            hash: hash
        });
    }

}

export default new Tickets();
