import log from "loglevel";
import { instance } from "./service";

class Emails {

    domains() {
        log.debug(`domains`);
        return instance.get("/emails/domains");
    }

    request(data: any) {
        log.debug(`request`, data);
        return instance.post("/emails/request", data);
    }

    history() {
        log.debug(`history`);
        return instance.get("/emails/history");
    }

}

export default new Emails();
