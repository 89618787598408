import log from "loglevel";
import { toast } from "react-toastify";
import { RootStore } from "./root-store";

const { makeAutoObservable } = require("mobx");

/**
 * Стор для выбора аттачей
 */
class AttacheStore {

    public rootStore: RootStore;

    private _attaches = [];

    // region getter & setters
    get attaches() {
        return this._attaches;
    }
    set attaches(val) {
        this._attaches = val;
    }
    // endregion


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    addAttache(file: any) {
        log.debug("addAttache", file);
        for (let attache of this.attaches) {
            // @ts-ignore
            if (attache.name === file.name && attache.lastModified === file.lastModified && attache.size === file.size) {
                return toast.error("The file has already been attached");
            }
        }
        // проверка кол-ва аттачей
        const max_files = this.rootStore.configStore.config.upload.max_upload_files;
        if (this.attaches.length >= max_files) {
            return toast.error(`Only ${max_files} files are allowed to be attached`);
        }
        // проверка размера аттача
        const max_file_size = this.rootStore.configStore.config.upload.max_upload_file_size_kb;
        if (file.size / 1024 > max_file_size) {
            return toast.error(`The maximum size of attached files is no more than ${max_file_size / 1024} MB`);
        }
        // @ts-ignore
        this.attaches.push(file);
    }

    deleteAttache(num: number) {
        this.attaches.splice(num, 1);
    }

    clearAttaches() {
        this.attaches = [];
    }

}

export default AttacheStore;