import log from "loglevel";
import { toast } from "react-toastify";
import axios from "axios";

import { RootStore } from "../root-store";
import icon_copy from "@assets/img/icons/copy.svg";
import icon_check from "@assets/img/icons/check-circle.svg";
import icon_cart from "@assets/img/icons/shopping-cart.svg";
import icon_search from "@assets/img/icons/search.svg";
import icon_close from "@assets/img/icons/close.svg";
import icon_lock from "@assets/img/icons/lock.svg";
import icon_trash from "@assets/img/icons/trash.svg";
import icon_message from "@assets/img/icons/message.svg";
import icon_frame from "@assets/img/icons/frame.svg";
import icon_arrow_left from "@assets/img/icons/arrow_left.svg";
import icon_check_all from "@assets/img/icons/check-all.svg";
import icon_close_box from "@assets/img/icons/close-box.svg";
import icon_clock from "@assets/img/icons/clock-outline.svg";
import icon_close_circle from "@assets/img/icons/close-circle-outline.svg";
import icon_check_circle from "@assets/img/icons/check-circle-outline.svg";
import icon_web from "@assets/img/icons/web.svg";
import icon_basket from "@assets/img/icons/basket.svg";
import icon_warning from "@assets/img/icons/warning.svg";

const { makeAutoObservable } = require("mobx");

/**
 * Стор для работы с картинками (вставки svg-картинок в страницы для возможности смены fill)
 */
class ImagesStore {

    // список загружаемых картинок
    public icons_list = [
        icon_copy, icon_check, icon_cart, icon_search, icon_close, icon_lock, icon_trash, icon_message,
        icon_frame, icon_arrow_left, icon_check_all, icon_close_box, icon_clock, icon_close_circle,
        icon_check_circle, icon_web, icon_basket, icon_warning
    ]

    public rootStore: RootStore;
    public _cache: any = {}

    get cache() {
        return this._cache;
    }
    set cache(val) {
        this._cache = val;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);

        this.icons_list.map(async icon => {
            await this.loadFile(icon)
        });
    }

    async loadFile(file_url: string) {
        log.debug("loadFile file_url:" + file_url);
        try {
            const res = await axios.get(file_url);
            this.cache[file_url] = res.data;
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default ImagesStore;