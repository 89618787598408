import log from "loglevel";
import { toast } from "react-toastify";
import { toJS } from 'mobx';
import { RootStore } from "../root-store";
import { MSG } from "@config/messages";
import Api from "@api/super-search";
import {SuperSearchFormData} from "../../types/SuperSearchFormData";
const { makeAutoObservable } = require("mobx");


/**
 * Стор для работы с поиском Super search
 */
class SuperSearchStore {

    public rootStore: RootStore;

    private formData: SuperSearchFormData = {
        firstname: "",
        lastname: "",
        dob: "",
        city: "",
        zip: "",
        state: ""
    };

    private _price: number = 0
    private _sid: number = 0;
    private _results: any = [];

    private _isHistory = true;
    private _toast: any;

    // region геттеры и сеттеры
    get firstname() {
        return this.formData.firstname;
    }
    set firstname(val) {
        this.formData.firstname = val;
    }
    get lastname() {
        return this.formData.lastname;
    }
    set lastname(val) {
        this.formData.lastname = val;
    }
    get dob() {
        return this.formData.dob;
    }
    set dob(val) {
        this.formData.dob = val;
    }
    get city() {
        return this.formData.city;
    }
    set city(val) {
        this.formData.city = val;
    }
    get zip() {
        return this.formData.zip;
    }
    set zip(val) {
        this.formData.zip = val;
    }
    get state() {
        return this.formData.state;
    }
    set state(val) {
        this.formData.state = val;
    }
    get price() {
        return this._price;
    }
    set price(val) {
        this._price = val;
    }
    get sid() {
        return this._sid;
    }
    set sid(val) {
        this._sid = val;
    }
    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }
    get isHistory() {
        return this._isHistory;
    }
    set isHistory(val) {
        this._isHistory = val;
    }
    // endregion


    setField(field: string, value: string) {
        // @ts-ignore
        this.formData[field] = value;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    clearForm() {
        for (let key of Object.keys(this.formData)) {
            // @ts-ignore
            this.formData[key] = "";
        }
    }

    async sendForm() {
        log.debug("sendForm", toJS(this.formData));
        let result = false;
        this._toast = toast.info(MSG.MSG_LOADING, {
            autoClose: 35000,
        });
        try {
            const res = await Api.search(this.formData);
            log.debug("SUPER_SEARCH", res);
            if (res.data.status === 'OK') {
                this.isHistory = false;
                this.price = res.data.price;
                this.sid = res.data.sid;
                this.results = res.data.data;
                toast.dismiss(this._toast);
                result = true;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug("SUPER_SEARCH ERROR", ex);
            //this.isHistory = true;
            //await this.history()
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
        finally {

        }
        return result;
    }

    async buy(rid: number) {
        log.debug("buy rid:", rid);
        try {
            this._toast = toast.info(MSG.MSG_LOADING);
            const res = await Api.buy(this.sid, rid);
            console.log(res);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                this.results = [ res.data.data ];
                await this.rootStore.pageStore.loadUserData();
                this.isHistory = true;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    async history(show_loading = false) {
        log.debug("history");
        try {
            if (show_loading) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            const res = await Api.history();
            console.log("HISTORY", res.data.data);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                this.results = res.data.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

}

export default SuperSearchStore;