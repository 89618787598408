import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "./root-store";
import { MmnFormData } from "../types/MmnFormData";
import { MSG } from "@config/messages";
import Api from "@api/mmn";


class MmnStore {

    public rootStore: RootStore;

    private _formData: MmnFormData = {
        firstname: "",
        lastname: "",
        middlename: "",
        address: "",
        city: "",
        zip: "",
        state: "",
        dob: "",
        ssn: "",
        extra: ""
    };

    private _results: any = [];

    private _toast: any;
    private _show_header = false;

    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }

    get showHeader() {
        return this._show_header;
    }
    set showHeader(val) {
        this._show_header = val;
    }


    get formData() {
        return this._formData;
    }

    getField(field: string) {
        // @ts-ignore
        return this._formData[field];
    }

    setField(field: string, value: string) {
        // @ts-ignore
        this._formData[field] = value;
    }

    clearForm() {
        log.debug("clearForm");
        for (let i in this.formData) {
            // @ts-ignore
            this.formData[i] = "";
        }
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async sendForm() {
        log.debug("sendForm", toJS(this.formData));
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            const res = await Api.request(this.formData);
            log.debug("MMN REQUEST", res);
            if (res.data.status === 'OK') {
                this.clearForm();
                await this.rootStore.pageStore.loadUserData();
                toast.dismiss(this._toast);
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    async history(show_loading = false) {
        log.debug("history");
        try {
            if (show_loading) {
                this._toast = toast.info(MSG.MSG_LOADING);
            }
            const res = await Api.history();
            console.log("HISTORY", res.data.data);
            if (res.data.status === 'OK') {
                toast.dismiss(this._toast);
                this.results = res.data.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

}

export default MmnStore;