import tg from "@assets/img/icons/tg.svg";
import onion from "@assets/img/icons/onion.svg";

let Config = {
    // @ts-ignore
    API: (/localhost/.test(window.document.location) ? 'http://ssn24.test' : '') + "/api",
    //API: '/api',
    modal_links: [
        {
            id: 0,
            icon: tg,
            link: '',
            class: 'tg',
            title: 'Telegram'
        },
        {
            id: 1,
            icon: onion,
            link: '',
            class: '',
            title: 'Onion'
        }
    ]
}

export default Config;