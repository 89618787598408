import _ from "lodash";
import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "./root-store";
import Api from "@api/tox";
import { MSG } from "@config/messages";
import Utils from "@helpers/utils";


class ToxStore {

    public rootStore: RootStore;

    private _config: any = {}
    private _formData: any = {
        bins: "",
        country: "",
        state: "",
        city: "",
        base: "",
        type: "",
        level: "",
        bank: "",
        selltype: "",
        zips: ""
    }
    private _toast: any;
    private _page = 0;
    private _pages = 0;
    private _results: any = [];
    private _isHistory = false;
    private _cart = [];
    private _orders: any = [];

    // region Getters & Setters
    get config() {
        return this._config;
    }
    set config(val) {
        this._config = val;
    }

    get formData() {
        return this._formData;
    }
    set formData(val) {
        this._formData = val;
    }

    get page() {
        return this._page;
    }
    set page(val) {
        this._page = val;
    }
    get pages() {
        return this._pages;
    }
    set pages(val) {
        this._pages = val;
    }

    get results() {
        return this._results;
    }
    set results(val) {
        this._results = val;
    }

    get isHistory() {
        return this._isHistory;
    }
    set isHistory(val) {
        this._isHistory = val;
    }

    get cart() {
        return this._cart;
    }
    set cart(val) {
        this._cart = val;
    }

    get orders() {
        return this._orders;
    }
    set orders(val) {
        this._orders = val;
    }

    get selectedCount() {
        // @ts-ignore
        return (this.results.filter(item => item.selected)).length;
    }

    setField(field: string, value: any) {
        log.debug(`setField field:${field} value:${value}`);
        // @ts-ignore
        this.formData[field] = value;
        if (field === 'country') {
            this.formData.state = null;
        }
    }
    // endregion

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    /**
     * Поиск записи по id в списке результатов
     * @param id
     */
    findResultItem(id: number) {
        if (this.results) {
            const res = toJS(this.results).filter((item: any) => item.id === id);
            if (res.length) {
                return res[0];
            }
        }
    }

    /**
     * Поиск записи по id в списке заказов
     * @param id
     */
    findOrderItem(id: number) {
        if (this.orders) {
            const res = toJS(this.orders).filter((item: any) => item.id === id);
            if (res.length) {
                return res[0];
            }
        }
    }

    deleteFromCart(id: number) {
        log.debug("deleteFromCart id:", id);
        this.cart = this.cart.filter((item: any) => item.id !== id);
    }

    /**
     * Сумма и кол-во товаров в корзине
     */
    cartTotal() {
        const res = {
            count: 0,
            sum: 0
        }
        this._cart.map(item => {
            res.count++;
            // @ts-ignore
            res.sum += parseFloat(item.price);
        });
        return res;
    }

    clearForm() {
        Api.abort();
        toast.dismiss();
        this.pages = 0;
        this.results = [];
        for (let key of Object.keys(this.formData)) {
            // @ts-ignore
            this.formData[key] = "";
        }
    }

    /**
     * Форматирование Exp даты из формата DD/MM в MM/DD
     * @param exp       Дата
     */
    formatExpDate(exp: string) {
        let res: any = exp;
        if (/^\d{2}\/\d{2}$/.test(exp)) {
            res = res.split("/");
            res = res[1] + "/" + res[0];
        }
        return res;
    }

    /**
     * Возвращает общий синоним страны для разных вариантов ее написания (см. API tox_structures.js)
     */
    getSelectedCountrySynonym() {
        console.log("getSelectedCountrySynonym", this.formData.country?.value);
        if (this.formData.country) {
            let res = this.formData.country.value;
            for (let i in this.config.countries_synonyms) {
                if (this.config.countries_synonyms[i].includes(this.formData.country.value)) {
                    res = i;
                    break;
                }
            }
            return res;
        }
    }

    /**
     * Преобразует данные конфига в формат, требуемый для ReactSelect
     * @param array         массив данных
     * @param type          тип данных (см. switch в коде)
     */
    convertArrayToSelectData(array: any[], type: string) {
        let res = array;
        if (array) {
            switch (type) {
                case "levels":
                case "types":
                    res = [ { value: "", label: "N/A" } ];
                    for (let i in array) {
                        res.push({
                            value: array[i],
                            label: array[i]
                        });
                    }
                    break;
                case "bases":
                    res = [ { value: "", label: "N/A" } ];
                    for (let i of array) {
                        res.push({
                            value: i.id,
                            label: (i.name ? i.name.substr(0, 20) : "Empty") + " (" + i.cnt + " pcs)"
                        });
                    }
                    break;
                case "countries":
                    res = [ { value: "", label: "N/A" } ];
                    for (let i of array) {
                        res.push({
                            value: i.name,
                            label: (i.name ? i.name.substr(0, 23) : "Empty") + " (" + i.cnt + " pcs)"
                        });
                    }
                    break;
            }
        }
        return res;
    }

    async loadConfig() {
        log.debug("loadConfig");
        try {
            const res = await Api.config();
            this.config = res.data.data;
        } catch (ex: any) {
            console.log("ERROR", ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async sendForm(page: number, showLoading = true) {
        log.debug("sendForm", page);
        let result = false;
        try {
            toast.dismiss(this._toast);
            this.page = page;
            if (showLoading) {
                this._toast = toast.info(MSG.MSG_WAIT, { autoClose: false });
            }
            const data = _.cloneDeep(this.formData);

            data.bins = data.bins ? data.bins.trim().split("\n") : [];
            data.zips = data.zips ? data.zips.trim().split("\n") : [];

            if (data.country) {
                data.country = data.country.value ?? "";
            }

            if (data.state && typeof(data.state) === "object") {
                data.state = data.state.value;
            }
            data.state = data.state ?? "";

            if (data.base) {
                data.base = "" + data.base.value;
            }
            if (data.type) {
                data.type = data.type.value.trim();
            }
            if (data.level) {
                data.level = data.level.value;
            }
            if (data.selltype) {
                data.selltype = data.selltype.value ?? "";
            }

            this.results = [];
            this.pages = 0;
            // @ts-ignore
            data['page'] = this.page;
            const res = await Api.search(data);
            log.debug("TOX", res);
            if (res.data.status === 'OK') {
                this.results = res.data.data.data;
                this.pages = res.data.data.last_page;
                console.log("RESULTS", toJS(this));
                toast.dismiss();
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response?.data.message || ex.message);
        }
        Api.finish();
        return result;
    }

    async buy() {
        log.debug("buy", toJS(this.cart));
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            const data = this.cart.map((row: any) => {
                return {
                    id: row.id,
                    price: row.price,
                    hash: row.hash
                };
            });
            const res = await Api.buy(data);
            console.log("BUY RESULT", res);
            if (res.data.status === 'OK') {
                for (let item of res.data.data.processed) {
                    this.deleteFromCart(item);
                }
                toast.dismiss(this._toast);
                this._toast = toast.info(Utils.stringFormat(MSG.MSG_SUCCESS_PURCHASED, [res.data.data.processed.length]));
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
            await this.rootStore.pageStore.loadUserData();
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
    }

    async check(transaction_id: number) {
        log.debug("check transaction_id", transaction_id);
        this._toast = toast.info(MSG.MSG_LOADING);
        try {
            const res = await Api.check(transaction_id);
            console.log("CHECK RESULT", res);
            if (res.data.status !== 'OK') {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.dismiss(this._toast);
            toast.error(ex.response.data.message || ex.message);
        }
        await this.loadOrders();
        await this.rootStore.pageStore.loadUserData();
    }

    async loadOrders() {
        log.debug("loadOrders");
        try {
            const res = await Api.orders();
            console.log("ORDERS", res.data);
            if (res.data.status === 'OK') {
                this._orders = res.data.orders;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

}

export default ToxStore;