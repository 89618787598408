import log from "loglevel";
import { instance } from "./service";

class Fullz {

    sources() {
        log.debug("sources");
        return instance.get("/fullz/sources");
    }

    search(data: any) {
        log.debug(`search`, data);
        return instance.post("/fullz/search", data);
    }

    buy(items: any) {
        log.debug(`buy items`, items);
        return instance.post(`/fullz/buy`, { items: items });
    }

    orders() {
        log.debug("orders");
        return instance.get("/fullz/orders");
    }

}

export default new Fullz();
