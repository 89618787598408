import log from "loglevel";
import React, {useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { observer } from "mobx-react-lite";

import { toast, ToastContainer } from 'react-toastify';
import { Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import AppRoutes from "@routing/app-routes";
import Error from "@components/error/Error";
import Modal from "@components/modal/Modal";
import sockets from "@api/sockets";
import { useStore } from "@hooks/use-store";
import  "../../css/paging.scss";


const App = () => {

    // region Логирование всех событий на шине EventBus
    const rootStore = useStore();
    rootStore.emitter.on('*', (type, e) => console.log(type, e));
    // endregion

    const { configStore } = useStore();

    // загрузка списка стран, штатов
    useEffect(() => {
        log.debug("useEffect");
        configStore.loadCountries().then();
        configStore.loadUsaStates().then();
        configStore.loadCanadaStates().then();
    }, []);

    // Логирование всех сообщений сокета
    sockets.subscribe("app", (msg: any) => {
        //toast.info(JSON.stringify(msg));
    });

    return (
        <BrowserRouter>
            <AppRoutes/>
            <Error/>
            <Modal/>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                theme="colored"
                closeOnClick
                hideProgressBar={true}
                draggable
                transition={Bounce}
            />
        </BrowserRouter>
    );
}

export default observer(App);
