import log from "loglevel";
import { toast } from "react-toastify";
import {makeAutoObservable, toJS} from "mobx";

import { RootStore } from "./root-store";
import Api from "@api/vft";


class VftStore {

    public rootStore: RootStore;

    protected maxCheckCount = 300;
    protected checkInterval = 3000;
    protected tm: any = null;

    protected _mode: string = "about";
    protected _submode: string = "basket";
    protected _task_id = "";
    protected _sections: any = [];
    protected _purchases: any = null;
    protected _form_data: any = {};
    protected _status = "";
    protected _previews: any = [];
    protected _service_id = 0;

    protected _tabs: any = [];
    protected _fields: any = {};

    // region Getters / Setters
    get mode() {
        return this._mode;
    }
    set mode(val) {
        this._mode = val;
    }

    get submode() {
        return this._submode;
    }
    set submode(val) {
        this._submode = val;
    }

    get task_id() {
        return this._task_id;
    }
    set task_id(val) {
        this._task_id = val;
    }

    get sections() {
        return this._sections;
    }
    set sections(val) {
        this._sections = val;
    }

    get purchases() {
        return this._purchases;
    }
    set purchases(val) {
        this._purchases = val;
    }

    get formData() {
        return this._form_data;
    }
    set formData(val) {
        this._form_data = val;
    }

    get status() {
        return this._status;
    }
    set status(val) {
        this._status = val;
    }

    get service_id() {
        return this._service_id;
    }
    set service_id(val) {
        this._service_id = val;
    }

    get tabs() {
        return this._tabs;
    }
    set tabs(val) {
        this._tabs = val;
    }

    get fields() {
        return this._fields;
    }
    set fields(val) {
        this._fields = val;
    }

    getPreviews(name: string) {
        return this._previews[name];
    }
    setPreviews(name: string, val: any) {
        this._previews[name] = val;
    }
    // endregion


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        if (this.rootStore.authStore.isAuth()) {
            this.loadSections().then();
        }
    }

    async loadSections() {
        log.debug("loadSections");
        try {
            const res = await Api.sections();
            console.log("SECTIONS", res.data);
            if (res.data.status === 'OK') {
                this.sections = res.data.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async loadPurchases() {
        log.debug("loadPurchases");
        try {
            const res = await Api.purchases();
            console.log("PURCHASES", res.data);
            if (res.data.status === 'OK') {
                this.purchases = res.data.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async loadSectionFormData(section_id: string) {
        log.debug("loadSectionFormData");
        try {
            this.mode = "loading";
            this.formData = {};
            const res = await Api.section(section_id);
            console.log("FORM", res.data);
            if (res.data.status === 'OK') {
                this.formData = res.data.data;
                this.service_id = res.data.data.service_id;
                this.mode = "form";

                // region Формируем список имен полей для валидации
                const steps = toJS(this.formData.steps);
                let i = 0;
                for (let step of steps) {
                    this.tabs[i] = {
                        errors: 0
                    };
                    for (let field of step.fields) {
                        this.fields[field.input_name] = {
                            error: false,
                            tab: i,
                            required: field.required,
                            regexp: field.regular_expression ? new RegExp(field.regular_expression) : null
                        };
                    }
                    i++;
                }
                // endregion
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async sendData(slug: string, data: any) {
        console.log('sendData', slug, data);
        let fd = new FormData();
        for (let i in data) {
            fd.append(i, typeof(data[i]) === 'object' ? data[i]?.value : data[i]);
        }
        try {
            const res = await Api.sendData(slug, fd);
            console.log("SEND", res.data);
            if (res.data.status === "OK") {
                this.startTimer(res.data.data.task_id);
            } else {
                toast.error(res.data.message);
                this.rootStore.modalStore.isShow = true;
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
            this.rootStore.modalStore.isShow = true;
        }
    }

    async checkStatus(task_id: string) {
        log.debug("checkStatus", task_id);
        try {
            const res = await Api.status(task_id);
            console.log("STATUS", res.data);
            const data = res.data;
            if (data.status === "OK") {
                this.status = data.data.task_status;
                if (data.data.task_status === 'end') {
                    clearInterval(this.tm);
                    this.formData.preview = data.data.image_url;
                    this.task_id = task_id;
                }
                if (data.data.task_status === 'error') {
                    clearInterval(this.tm);
                    toast.error("Error result generate");
                }
            } else {
                toast.error(res.data.message);
            }
        } catch (ex) {
            log.debug(ex);
        }
    }

    startTimer(task_id: string) {
        console.log("startTimer");
        let counter = this.maxCheckCount;
        this.tm = setInterval(async () => {
            if (counter-- > 0) {
                await this.checkStatus(task_id);
            } else {
                clearInterval(this.tm);
                toast.error("An error has occurred. Please try again later.");
            }
        }, this.checkInterval);
    }

    async addToBasket() {
        log.debug("addToBasket");
        try {
            this.mode = "loading";
            const data = {
                task_id: this.task_id,
                service_id: this.service_id,
                preview: this.formData.preview
            }
            console.log(data);
            const res = await Api.add_basket(data);
            console.log("SEND", res.data);
            if (res.data.status === "OK") {
                await this.loadPurchases();
                this.mode = "basket";
                this.submode = "basket";
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            console.log(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async buy(task_id: string) {
        log.debug("buy", task_id);
        try {
            const res = await Api.buy(task_id);
            console.log("BUY", res);
            const data = res.data;
            if (data.status === "OK") {
                await this.loadPurchases();
                await this.rootStore.pageStore.loadUserData();
                this.submode = "history";
            } else {
                this.submode = "basket";
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response.data.message);
            this.submode = "basket";
        }
        this.mode = "basket";
    }

    async delete(task_id: string) {
        log.debug("delete", task_id);
        try {
            const res = await Api.delete(task_id);
            console.log("DELETE", res);
            const data = res.data;
            if (data.status === "OK") {
                await this.loadPurchases();
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug("ERROR", ex);
            toast.error(ex.response.data.message);
        }
    }

}

export default VftStore;